<template>
  <Navigation v-if="this.$route.name !== 'home'" class="text-cera" />
  <router-view />
</template>

<script>
import Navigation from "@/components/layout/navigation/Navigation.vue";
export default {
  components: {
    Navigation
  },
  mounted() {
    if (this.$store.state.mainTrack === null) {
      this.$router.push({ path: "/" });
    }
  },

  watch: {
    '$store.state.mainTrack'(value) {
      if (value === null && this.$route.name !== 'GeneralChoices') {
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>
<style lang="scss">
/* Cera Bold */
@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Cera-Pro-Bold.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Cera Medium */
@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Cera-Pro-Medium.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Cera Regular */
@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Cera-Pro-Regular.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Cera light */
@font-face {
  font-family: 'Cera';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Cera-Pro-Light.woff') format('woff'),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
#app {
  font-family: 'Cera';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
