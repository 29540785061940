import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import "./styles/app.css"; // Here
import "./registerServiceWorker";
import router from "./router";
import VueConfetti from 'vue-confetti'
// import store from "./store";
// import { createStore } from "vuex";
const store = createStore({
  /* state, actions, mutations */
  state: {
    mainTrack: null,
    track: null,
    subTrack: null,
    latestTrackCode: null,
    historyUrl: null,
    tellManager: null,
    name: null,
    currentFunction: null,
    currentLocation: null,
    currentLocationLat: null,
    currentLocationLong: null,
    comment: null,
    userAdress: null,
    zipcode: null,
    houseNr: null,
    vacancyId: null,
    travelTime: null,
    streetTotal: null,
    managerName: null,
    managerEmail: null,
    vacancySelected: null,
  },
});


createApp(App).use(router).use(store).use(VueConfetti).mount("#app");
