import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/general-introduction",
    name: "GeneralIntroduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GeneralIntroduction.vue"),
  },
  {
    path: "/general-choices",
    name: "GeneralChoices",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GeneralChoices.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/playground",
    name: "playground",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PlaygroundView.vue"),
  },
  {
    path: "/introduction/:id/",
    name: "introduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Introduction.vue"),
  },
  {
    path: "/form/:id",
    name: "form",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Form.vue"),
  },
  {
    path: "/vacancies",
    name: "vacancies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Vacancies.vue"),
  },
  {
    path: "/overview/:id/",
    name: "overview",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Overview.vue"),
  },
  {
    path: "/explanation",
    name: "explanation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Video.vue"),
  },
  {
    path: "/end-vacancies",
    name: "end vacancies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EndVacancies.vue"),
  },
  {
    path: "/tips",
    name: "tips",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Tips.vue"),
  },
  {
    path: "/end",
    name: "end",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/End.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
