<template>
  <div class="box-shadow absolute top-0 left-0 w-full bg-white py-2 z-30" :class="informationActive && '!fixed top-0 left-0' ">
    <Container class="!pb-0 z-10">
      <div class="block relative" >
        <img
          src="@/assets/img/logo.svg"
          class="w-8 h-8 absolute left-0 top-0"
        />
      </div>
      <div
        class="text-[12px] text-center absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[#1d1d1b80]"
      >
        Partou helpt je verder
      </div>
      <div class="cursor-pointer" @click="informationActive = !informationActive">
        <img src="@/assets/img/icons/help.svg" class="w-2 h-2 ml-auto" v-if="!informationActive"/>
        <img src="@/assets/img/icons/close.svg" class="w-2 h-2 ml-auto" v-else/>
      </div>
      
    </Container>
    <div class="fixed w-full h-full bg-white px-2 pt-9 py-6" v-if="informationActive">
      <div class="max-w-[720px] mx-auto block">
        <div class="font-bold text-[20px] leading-[28px] text-[#1D1D1B] mb-2">Over de tool</div>
      <div class="font-bold">In het kort</div>
      <div class="mb-3">Tijdens je loopbaan bij Partou kunnen er verschillende redenen zijn waardoor je wilt wisselen van plek of functie. Partou helpt je verder. Samen kijken we naar jouw mogelijkheden.</div>
      <div class="font-bold">In het kort</div>
      <div class="mb-3">Neem contact met ons op via <a href="mailto:mobiliteit@partou.nl" class="underline">mobiliteit@partou.nl</a>.</div>
      <div class="relative block mb-2 rounded-[8px] border-[1px] border-[#1d1d1b29]">
        <a target="_blank" href="mailto:mobiliteit@partou.nl" class="p-2 border-b-[#1d1d1b29] border-b-[1px] cursor-pointer flex justify-center items-center">
          <img alt="img" src="@/assets/img/icons/mail.svg" class="w-3 h-2 mr-1.5"/>
          <div class="font-bold">
            Mail je vraag
          </div>
        </a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "@/components/ui/container/Container.vue"; // @ is an alias to /src

export default {
  data() {
    return {
      informationActive: false,
    }
  },
  method:{
    toggleInformation(){
      this.informationActive = !this.informationActive
    }
  },
  watch:{
    informationActive(){
      this.$emit('informationMenu', this.informationActive)
    }
  },
  components: {
    Container,
  },
};
</script>

<styles lang="scss">
.box-shadow {
  box-shadow: 0px 4px 12px rgba(29, 29, 27, 0.04);
}
</styles>
