<template>
  <Header home noNavigation />
  <Container class="text-cera" >
    <div class="text-[32px] leading-[40px] text-center my-[24px]">
      <span class="font-bold">Hé</span> jij daar!
    </div>
    <div class="rounded aspect-video bg-red-500 mb-[24px] overflow-hidden relative">
      <img
          src="@/assets/img/misc/start.jpg"
          class="w-full absolute top-[50%] translate-y-[-50%]"
        />
    </div>
    <p class="mb-[24px] text-center">
      Wat goed dat je ons gevonden hebt! Heb je het idee dat je niet meer op je
      plek zit of zoek je een nieuwe uitdaging? Klik op start en zet de eerste
      stap in de juiste richting!
    </p>
    <router-link to="/general-introduction">
      <div
        class="flex button-gradient rounded-[24px] py-1 px-2 hover:opacity-70 transition justify-center"
      >
        <span class="text-white font-bold leading-[24px]">Start</span>
        <img
          src="@/assets/img/icons/arrow-left.svg"
          class="w-3 h-3 ml-1.5 rotate-[180deg]"
        />
      </div>
    </router-link>
    <div :class="`${showPopup ? 'bottom-2' : 'bottom-[-100%]'} w-[calc(100%_-_32px)] bg-[#582354] rounded fixed  transition left-[50%] translate-x-[-50%] p-2 flex flex-wrap items-start justify-between`" class="">
      <img src="@/assets/img/icons/task_alt.svg" class=""/>
      <p class="text-white w-[calc(100%_-_40px)]">
        Je hebt de tool succesvol doorlopen! Heb je toch nog vragen? Neem dan contact op met mobiliteit@partou.nl.
    </p>
    </div>
  </Container>
</template>

<script>
import Header from "@/components/sections/header/Header.vue";
import Container from "@/components/ui/container/Container.vue";

export default {
  components: {
    Header,
    Container,
  },
  data() {
    return {
      showPopup: false
    };
  },
  mounted() {
    window.scroll(0, 0);
    const state = this.$store.state;
    const newState = {};

    Object.keys(state).forEach(key => {
      newState[key] = null; // or = initialState[key]
    });

    if (this.$store.state.finished) {
      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false
      }, 6000)
    }
    this.$store.replaceState(newState);
  }
};
</script>

<style lang="scss">
.button-gradient {
  background: linear-gradient(90deg, #b61544 0%, #ef575d 100%);
}
</style>
