<template>
  <div class="relative h-[144px]" :class="!noNavigation && 'mt-[48px]'">
    <div class="block relative h-[80px] bg-[#F2E9E2]">
      <Container class="relative h-full">
        <div class="partou-circle ring-image"></div>
        <div class="partou-circle--two ring-image"></div>
        <div class="partou-circle--three ring-image"></div>
      </Container>
    </div>
    <div class="bg-white relative h-[64px] z-10 oval-after">
      <img
        :src="
          require(`@/assets/img/${
            !home ? 'header/' + buddy + '.png' : 'logo.svg'
          }`)
        "
        class="w-[100px] absolute left-[50%] translate-x-[-50%] top-[-50px] z-10"
        alt="buddy image"
      />
    </div>
  </div>
</template>

<script>
import Container from "@/components/ui/container/Container.vue"; // @ is an alias to /src

export default {
  props: {
    buddy: {
      type: String,
      default: "buddy-3",
    },
    noNavigation: {
      type: Boolean,
      default: false,
    },
    home: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Container,
  },
};
</script>

<style lang="css">
.partou-circle {
  @apply w-[100px] h-[100px] bottom-[-70px] absolute rotate-[-10deg];
}

.partou-circle--two {
  @apply w-[130px] h-[130px] top-[-100px] left-[20%] absolute rotate-[-10deg];
}

.partou-circle--three {
  @apply w-[150px] h-[150px] bottom-[-90px] right-[16px] absolute rotate-[-10deg];
}
</style>

<style lang="scss">
.ring-image {
  background-image: url("~@/assets/img/icons/ring.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.oval-after:after {
  content: "";
  position: absolute;
  left: 0;
  top: -12px;
  width: 100%;
  height: 24px;
  border-radius: 100%;
  background-color: white;
  z-index: 1;
}
</style>
